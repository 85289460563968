/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/bootstrap.native@3.0.14-f/dist/bootstrap-native.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe.min.js
 * - /npm/photoswipe@4.1.3/dist/photoswipe-ui-default.min.js
 * - /npm/zxcvbn@4.4.2/dist/zxcvbn.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
